import { Link } from 'gatsby';
import React from 'react';

import { BaseTags } from './TagsComponent';
import './../styles/drops.css';

const Drop = ({ data }) => {
  const {
    frontmatter: { title, date, tags },
    html,
  } = data;

  return (
    <React.Fragment>
      <div className="flex flex-col pt-10 pb-8">
        <h1 className="text-4xl">
          <Link to={`/drops`}>
            <span
              className="hover:underline hover:bg-blue-300"
              role="img"
              aria-label="back to blog"
            >
              🔙
            </span>{' '}
          </Link>
          {title}
        </h1>
        <div className="sm:h-12 flex sm:flex-row items-center justify-between">
          <BaseTags
            className="flex text-sm"
            wrapperClass="pr-2"
            tagClass="p-2"
            tags={tags}
          />
          <p className="text-xs sm:block">
            {new Date(date).toLocaleDateString('en-GB')}
          </p>
        </div>
      </div>
      <div
        className="drops pb-16 drops"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </React.Fragment>
  );
};

export { Drop };
