import React from 'react';
import { graphql } from 'gatsby';

import { Drop } from '../components/Drop';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

function DropsTemplate({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Drop data={{ frontmatter, html }} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        slug
        title
        tags
      }
    }
  }
`;

export default DropsTemplate;
