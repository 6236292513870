import { Link } from 'gatsby';
import classnames from 'classnames';
import React from 'react';

const Tag = ({ tag, tagClass }) => {
  const className = classnames(
    'bg-gray-300 rounded-md px-2 hover:bg-gray-500',
    tagClass
  );
  return (
    <Link key={tag} to={`/tags/${tag}`}>
      <span className={className}>{tag}</span>
    </Link>
  );
};

const BaseTags = ({ tags, className, wrapperClass, tagClass, ...rest }) => (
  <div className={className} {...rest}>
    {tags.map(tag => (
      <div className={wrapperClass} key={tag}>
        <Tag tag={tag} tagClass={tagClass} />
      </div>
    ))}
  </div>
);

const Tags = ({ tags, className, wrapperClass, ...rest }) => {
  const classNames = classnames(
    'flex justify-between flex-wrap w-full text-xs pt-2 gap-2',
    className
  );
  return (
    <BaseTags
      className={classNames}
      wrapperClass={wrapperClass}
      tags={tags}
      {...rest}
    />
  );
};

export { Tag, Tags, BaseTags };
